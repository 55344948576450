













































































































const ViewStateEnum = {
  List: 'List',
  Select: 'Select',
  Add: 'Add',
  Edit: 'Edit'
} as const

type FrontendPunishActions = Array<IterableListItem<BaseBuilderClass>>

import { IterableItemFactory } from "@/components/TriggerSetup/logic/factories/iterable-item-factory";
import { IterableListItem } from "@/components/TriggerSetup/logic/types/types";
import PunishActionPreview from "@/components/Modules/punish-actions/components/PunishActionPreview.vue";
import {
  IncrementalAction,
  PunishActions,
  PunishActionTypesEnum
} from "@/includes/logic/Modules/models/PunishActions/types";
import { FactoryBuilderClass } from "@/includes/logic/Modules/Factories/PunishActionsBuilderFactory";
import { BaseBuilderClass } from "@/includes/logic/Modules/Builders/PunishActionBuilder";

import AddEntityCard from "piramis-base-components/src/components/AddEntityCard.vue";
import { SelectOption } from "piramis-base-components/src/logic/types";
import BlockMovingControls from "piramis-base-components/src/components/BlockMovingControls/BlockMovingControls.vue";
import WizardLikeListItems from "piramis-base-components/src/components/WizardLikeSelect/WizardLikeListItems.vue";

import { Component, Emit, Prop, VModel, Vue, Watch } from 'vue-property-decorator';
import { cloneDeep } from "lodash";
import { ValidationObserver } from "vee-validate";

@Component({
  components: {
    PunishActionPreview,
    WizardLikeListItems,
    AddEntityCard,
    ValidationObserver,
    BlockMovingControls
  }
})
export default class IncrementalActionView extends Vue {
  @VModel() model!: IncrementalAction

  @Prop() punishActionBuilders!: Array<BaseBuilderClass>

  @Emit()
  onSaveClick() {
    return
  }

  @Emit()
  onDisableClick() {
    return null
  }

  @Watch('frontendActions', { deep: true })
  onFrontendActionsChange(value: FrontendPunishActions) {
    this.model.actions = this.getRawActions(value)
  }

  viewState: keyof typeof ViewStateEnum = "List"

  frontendActions: FrontendPunishActions = []

  currentActionBuilder: IterableListItem<BaseBuilderClass> | null = null

  itemFactory = new IterableItemFactory()

  listViewStateOption = { label: this.$t('punish_action_incremental_view_state_list').toString(), value: ViewStateEnum.List }
  selectViewStateOption = { label: this.$t('punish_action_incremental_view_state_select').toString(), value: ViewStateEnum.Select }

  simpleBreadcrumbs: Array<SelectOption> = [ this.listViewStateOption ]

  goto(index: number) {
    this.simpleBreadcrumbs.splice(index + 1)

    const lastEl = this.simpleBreadcrumbs.at(-1)

    if (lastEl && Object.values(ViewStateEnum).some(el => el === lastEl.value)) {
      this.viewState = lastEl.value as keyof typeof ViewStateEnum
    }
  }

  onAddEntityCardClick() {
    this.viewState = "Select"

    this.simpleBreadcrumbs.push(this.selectViewStateOption)
  }

  handleSaveClick() {
    if (this.viewState === "Add") {
      this.addCurrentAction()
    }

    if (this.viewState === "Edit") {
      this.updateAction()
    }

    this.viewState = "List"
    this.simpleBreadcrumbs = [ this.listViewStateOption ]
  }

  updateAction() {
    if (this.currentActionBuilder) {
      const idx = this.frontendActions.findIndex(a => a.guid === this.currentActionBuilder!.guid)

      if (idx !== -1) {
        this.frontendActions.splice(idx, 1, this.currentActionBuilder)
      }
    }
  }

  addCurrentAction() {
    if (this.currentActionBuilder) {
      this.frontendActions.push(this.currentActionBuilder)
    }
  }

  onEditClick(action: IterableListItem<BaseBuilderClass>) {
    this.currentActionBuilder = cloneDeep(action)
    const actionIndex = this.frontendActions.findIndex(a => a.guid === action.guid)

    this.viewState = "Edit"
    this.simpleBreadcrumbs.push({
      label: `${ this.$t(this.currentActionBuilder.value.actionTitleKey()).toString() } #${ actionIndex + 1 } `,
      value: null
    })
  }

  onRemoveClick(guid: string) {
    this.frontendActions  = this.frontendActions.filter(a => a.guid !== guid)
  }

  selectItem(type: PunishActionTypesEnum) {
    const builder = cloneDeep(this.punishActionBuilders.find(b => b.model.type === type))

    if (builder) {
      this.currentActionBuilder = this.itemFactory.create(builder)

      if (this.currentActionBuilder) {
        this.viewState = "Add"

        this.simpleBreadcrumbs.push({ label: this.$t(this.currentActionBuilder.value.actionTitleKey()).toString(), value: null })
      }
    }
  }

  get listData() {
    return this.punishActionBuilders
      .filter(b => ![ PunishActionTypesEnum.Incremental, PunishActionTypesEnum.Disabled ].includes(b.model.type))
      .map(b => {
        return {
          key: b.model.type,
          title: this.$t(b.actionTitleKey()).toString(),
          helpMessage: this.$te(b.actionHelpMessageKey())? this.$t(b.actionHelpMessageKey()).toString() : null,
          icon: b.icon
        }
      })
  }

  getRawActions(items: FrontendPunishActions) {
    return items.map(value => value.value.model)
  }

  getFrontendActions(actions: Array<PunishActions>) {
    return actions
      .reduce((acc: FrontendPunishActions, value) => {
        const builder = FactoryBuilderClass.getBuilder(value.type)

        if (builder) {
          builder.updateModel(value)

          acc.push(this.itemFactory.create(builder))
        }

        return acc
      }, [])
  }

  created() {
    this.frontendActions = this.getFrontendActions(this.model.actions ?? [])
  }
}
